import fire from '../utils/firebase'
const db = fire.database().ref("personal-info");


const getAll = () => {
    return db;
};
const getData =(data) => {
    let result ='';
    db.child(data).on("value", function(snapshot) {
        result = snapshot.val()
      }, function (errorObject) {
        console.log("The read failed: " + errorObject.code);
      });
    // result = db.child(data).getValue()
    // console.log("db.child(data).getValue()", result)
    return result;

}
const create = (data) => {
    return db.set(data);
}; 

const update = (data) => {
    //  const arr = Object.entries(data)
    //  arr.forEach(([key, value])  => {
    //     console.log("check: key: ",key,' and value: ', value)
      
    //  })
    return db.child().update(data);
    // return db.child(id).update(value);
};

const remove = (key) => {
    return db.child(key).delete();
};

const removeAll = () => {
    return db.remove();
};

export default {
    getAll,
    getData,
    create,
    update,
    remove,
    removeAll
};