import React from 'react'
import {
    Route,
    Switch,
    BrowserRouter
} from "react-router-dom";
import PrivateRoute from './utils/PrivareRoute'
import { AuthProvider } from './services/AccountService'
import Home from './container/Home'
import SignUp from './container/Signup'
import SignIn from './container/Signin'
import Profile from './container/Profile'
import AddTutorial from './container/AddTutorial'
import CommonStore from './store/common'

import './styles.scss'
import Info from './container/Info';

function App() {
    return (
        <CommonStore>
            <BrowserRouter>
                <AuthProvider>
                    <Switch>
                        <PrivateRoute exact path="/add" component={AddTutorial} />
                        <PrivateRoute exact path="/profile" component={Profile} />
                        <Route exact path="/" component={Info} />
                        {/* <Route exact path="/sign-up" component={SignUp} /> */}
                        <Route exact path="/login" component={SignIn} />
                        <Route exact path="/tutorials" component={Home} />
                    </Switch>
                </AuthProvider>
            </BrowserRouter>
        </CommonStore>
    );
}

export default App
