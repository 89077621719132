import React, {useState, useEffect} from 'react'
import Header from '../../layouts/Header'
import TutorialsList from '../../components/TutorialsList'
import { useAuth } from '../../services/AccountService' 

function Home (props) {
	const { currentUser, logout } = useAuth()
	console.log("currentUser",currentUser)
	return (
		<>
			<Header/>
			<div className="container mt-3">
				{/* <h2>React Firebase Hooks CRUD</h2> */}
				<TutorialsList />
			</div>
		</>
	)
}
export default Home;