import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../services/AccountService";
import { Dropdown } from "react-bootstrap";

function Header(props) {
  const { currentUser, logout } = useAuth();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("false");
  const handleLogout = async () => {
    try {
      await logout();
      history.push("/login");
    } catch {
      setError(true);
      setMsg("Failed to log out");
    }
  };
  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <a href="/" className="navbar-brand">
            <img
              className="rounded-circle avatar"
			  style={{width: "40px", height: "40px"}}
              alt="Image Pham Quang Son"
              src="/static/img/info/avatar.jpg"
              aria-hidden
            />
          </a>
          <div className="collapse navbar-collapse">
            <div className="navbar-nav mr-auto">
              {/* <li className="nav-item">
                <Link to={"/info"} className="nav-link">
                  {" "}
                  Info
                </Link>
              </li> */}
              <li className="nav-item">
                <Link to={"/tutorials"} className="nav-link">
                  Tutorials
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/add"} className="nav-link">
                  Add
                </Link>
              </li>
            </div>
            {currentUser && (
              <>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    User account
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="/profile">
                      Account setting
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleLogout()}>
                      Log out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </div>
        </nav>
      </header>
    </>
  );
}
export default Header;
