import React, { useRef, useState, useEffect } from "react";
import Header from "../../layouts/Header";
import Particles from "react-particles-js";
import "font-awesome/css/font-awesome.min.css";
import { useList } from "react-firebase-hooks/database";
import TutorialDataService from "../../services/TutorialService";

function Info() {
  document.body.classList.add("infoWrapper");
  /* use react-firebase-hooks */
  const [tutorials, loading, error] = useList(TutorialDataService.getAll());
  return (
    <>
      <Particles
        params={{
          particles: {
            number: {
              value: 30,
            },
            size: {
              value: 3,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      />
      <div className="contentWrapper">
        <div className="container">
          <div className="row mb-5 justify-items-center align-items-center">
            <div className="col-md-3 text-center">
              <img
                className="avatar rounded-circle img-fluid mb-3"
                alt="Image Pham Quang Son"
                src="/static/img/info/avatar.jpg"
                aria-hidden
              />
            </div>
            <div className="col-md-9 text-uppercase">
              <h1>Phạm Quang Sơn</h1>
              <p className="title">Front-end web developer</p>
            </div>
          </div>
          <div className="basic-info mb-5">
            <h4 className="son-title">
              <span className="son-txt">ABOUT ME</span>
            </h4>
            <p>
              I was born and grew up in Ba Ria Vung Tau province, the
              countryside is close to the sea, giving me a tan nurtured my
              passion for exploration. My passion for learning and
              self-improvement is evident. I'm looking forward to the
              opportunity to contribute my skills.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="basic-info mb-5">
                <h4 className="son-title">
                  <span className="son-txt">CONTACT</span>
                </h4>
                <ul className="son-list list-unstyled">
                  <li>
                    <i className="fa fa-link"></i>
                    cv.phamquangson.com
                  </li>
                  <li>
                    <i className="fa fa-envelope"></i>
                    phamquangson4493@gmail.com
                  </li>
                  <li>
                    <i className="fa fa-phone"></i>
                    0369070077
                  </li>
                  <li>
                    <i className="fa fa-map-marker"></i>
                    Hồ Chí Minh
                  </li>
                </ul>
              </div>
              <div className="basic-info mb-5">
                <h4 className="son-title">
                  <span className="son-txt">EDUCATION</span>
                </h4>
                <p>Ho Chi Minh City University of Science</p>
                <em>
                  Bachelor of Science and Technology degree classification above
                  2/4
                </em>
              </div>
              <div className="basic-info mb-5">
                <h4 className="son-title">
                  <span className="son-txt">LANGUAGE</span>
                </h4>
                <p>English Upper Intermediate at ILA</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="basic-info mb-5">
                <h4 className="son-title">
                  <span className="son-txt">SKILL</span>
                </h4>
                <ul className="list-unstyled">
                  <li className="list-item mr-0">- HTML (Jade, Pug)</li>
                  <li className="list-item mr-0">- CSS (Sass, Less)</li>
                  <li className="list-item mr-0">- Boostrap</li>
                  <li className="list-item mr-0">- Javascript</li>
                  <li className="list-item mr-0">- Angular</li>
                  <li className="list-item mr-0">- VueJS</li>
                  <li className="list-item mr-0">- ReactJS</li>
                  <li className="list-item mr-0">- Redux</li>
                  <li className="list-item mr-0">- Redux Toolkit</li>
                  <li className="list-item mr-0">- NextJS</li>
                  <li className="list-item mr-0">- Frontend Odoo</li>
                  <li className="list-item mr-0">- Tailwindcss</li>
                  <li className="list-item mr-0">- GSAP</li>
                  <li className="list-item mr-0">- D3.js</li>
                  <li className="list-item mr-0">- SEO</li>
                  <li className="list-item mr-0">- Adobe Photoshop</li>
                </ul>
              </div>
              <div className="basic-info">
                <h4 className="son-title">
                  <span className="son-txt">HOBBIES</span>
                </h4>
                <ul className="son-list list-unstyled">
                  <li>
                    I am able to explore and self-study new technology such as
                    Next.js, React Vite
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="basic-info mb-5">
            <h4 className="son-title">
              <span className="son-txt">WORK EXPERIENCE</span>
            </h4>
            {/* call api firebase*/}
            {error && (
              <div>
                <strong>Error: {error}</strong>
              </div>
            )}
            {!loading &&
              tutorials &&
              tutorials.map((item, index) => (
                <div className="company-info mb-5" key={index}>
                  <p className="son-company-title mb-2 d-inline">
                    {item.val().companyName}
                  </p>
                  <p className="son-date d-inline">
                    {" | " + item.val().workTime}
                  </p>
                  <div className="son-des">
                    <h5>{item.val().jobTitle}</h5>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.val().description,
                      }}
                    ></p>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="waveWrapper waveAnimation">
          <div className="bgTop">
            <div
              className="wave waveTop"
              style={{
                backgroundImage: 'url("/static/img/info/wave-top.png")',
              }}
            ></div>
          </div>
          <div className="bgMiddle">
            <div
              className="wave waveMiddle"
              style={{
                backgroundImage: 'url("/static/img/info/wave-mid.png")',
              }}
            ></div>
          </div>
          <div className="bgBottom">
            <div
              className="wave waveBottom"
              style={{
                backgroundImage: 'url("/static/img/info/wave-bot.png")',
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Info;
