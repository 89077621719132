
import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../../services/AccountService"
import PersonalInfoService  from "../../services/PersonalService";
import { Link, useHistory } from "react-router-dom"
import Header from '../../layouts/Header'
import { useObject } from 'react-firebase-hooks/database'
function Profile(props) {

    const { currentUser } = useAuth()
    const [displayName, setDisplayName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [photoURL, setPhotoURL] = useState('')
    // const [error, setError] = useState(false)
    const [msg, setMsg] = useState('')
    // const [loading, setLoading] = useState(false)
    const history = useHistory()
    const [data, loading, error] = useObject(PersonalInfoService.getAll());
    useEffect(() => {
          if(data){
              let obj = data.val()
              setEmail(obj?.email)
              setPhoneNumber(obj?.phone)
              setPhotoURL(obj?.photoURL)
              setDisplayName(obj?.displayName)
          }
    }, [data])

    const handleUpdate = async() => {
        if(currentUser) {
            try{
                let data ={
                    displayName: displayName,
                    phone: phoneNumber,
                    photoURL: "https://example.com/jane-q-user/profile.jpg",
                    email: email
                      
                }
                let res = await PersonalInfoService.create(data)
                if(res) {
                    console.log("Check res", res)
                }
           }catch{
                // setError(true)
                setMsg("Failed to create an account")
           }
        }
       
    }

    return (<>
        <Header />
            <div className="container mt-3">
                <h2 className="text-center">User Profile</h2>
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        {photoURL && <div className="mb-2"><img className="img-fluid" src={photoURL} alt="pic"/></div>}
                        <div className="sign-up-form">
                            <div className={`form-group`}>
                                <div>Email address</div>
                                <input type="email" defaultValue={email} readOnly={true} className="form-control" />
                            </div>
                            <div className={`form-group ${error && displayName === "" ? 'error' : ''}`}>
                                <div>Display name</div>
                                <input type="text" value={displayName||''} onChange={(e) => setDisplayName(e.target.value)} className="form-control" />
                                {error && displayName === "" && <div className="text-error">Please enter your name</div>}
                            </div>
                            <div className={`form-group`}>
                                <div>Phone</div>
                                <input type="tel" value={phoneNumber||''} onChange={(e) => setPhoneNumber(e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group">
                                <button onClick={() => handleUpdate()} className="btn btn-success">Update</button>
                                
                            </div>
                        </div>
                    </div>

                </div>

            </div>
    </>)
}
export default Profile