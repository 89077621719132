import fire from '../utils/firebase'
const db = fire.database().ref("/tutorials");


const getAll = () => {
    return db;
};

const create = (data) => {
    return db.push(data);
}; 

const update = (id, value) => {
    return db.child(id).update(value);
};

const remove = (key) => {
    return db.child(key).delete();
};

const removeAll = () => {
    return db.remove();
};

export default {
    getAll,
    create,
    update,
    remove,
    removeAll
};